.slick-fullscreen {
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.slick-fullscreen.slick-dotted.slick-slider {
	margin-bottom: 45px;
}

.slick-fullscreen .slick-track {
	display: table;
	height: 100%;
}

.slick-fullscreen .slick-list {
	height: 100%;
}

.slick-fullscreen .slick-prev, .slick-fullscreen .slick-next {
	z-index: 1;
}

.slick-fullscreen .slick-next {
	right: 0;
}

.slick-fullscreen .slick-prev {
	left: 0;
}

.slick-fullscreen .slick-slide {
	display: table-cell;
	position: relative;
	vertical-align: middle; 
	cursor: auto;
	white-space: normal;
	outline: none;
	float: none;
	padding: 38px;
}

.slick-fullscreen .slick-slide img {
	margin-left: auto;
	cursor: pointer;
	max-width: 100%;
}

.slick-fullscreen .slick-active img {
	margin-right: auto;
}

.slick-fullscreen .slick-active ~ .slick-slide img {
	margin-left: 0;
}

.slick-fullscreen .slick-dots {
	background: rgba(0, 0, 0, 0.8);
	height: 45px;
	bottom: -45px;
}

.slick-fullscreen .slick-dots li {
	width: 23px;
	height: 23px;
}

.slick-fullscreen .slick-dots li button:before {
	color: #ccc;
}

.slick-fullscreen .slick-dots li.slick-active button:before {
	color: #fff;
}

.slick-fullscreen .slick-fullscreen-close {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #ccc;
	font-size: 14px;
	cursor: pointer;
	z-index: 3;
}

.slick-fullscreen .slick-fullscreen-close:hover {
	color: #fff;
}
